<template>
  <el-main>
    <div class="left">
      <div class="title">{{ siteInfo.name }}</div>
      <div class="banner">
        <div class="showBtn">{{ appType }}</div>
        <div class="desc">选择预览终端</div>
        <div :class="['imgBox', appType === item.name ? 'activeApp' : '']" v-for="(item, index) in appList" :key="index" @click="chooseApp(item)">
          <img :src="item.img" alt="" />
        </div>
      </div>
      <div class="title newTitle">新手指引</div>
      <div class="stepsBox">
        <div v-for="(item, index) in stepList" :key="index">
          <div :class="['stepsItem', 'mr7', item.isComplete ? 'isComplete' : '']">
            <div class="num" :style="{ background: item.isComplete ? '#1467ff' : '#DADADA' }">
              {{ index + 1 }}
            </div>
            <div class="name">{{ item.title }}</div>
            <div class="go" v-if="!item.isComplete" @click="goDeal(item, index)">前往设置</div>
            <div class="alreadyGo" v-else>前往设置</div>
          </div>
          <div v-if="index !== stepList.length - 1" class="yuanBox">
            <div
              v-for="(item, index1) in 3"
              :key="index1"
              :class="['yuan', 'mr7', index !== stepList.length && stepList[index].isComplete && stepList[index + 1].isComplete ? 'yuanActive' : '']"
            ></div>
          </div>
        </div>
      </div>
      <div class="title newTitle">最新推荐</div>
      <div class="courseList">
        <div class="courseItem" v-for="(item, index) in courseList" :key="index">
          <div class="img">
            <img :src="item.img" alt="" />
          </div>
          <div class="name">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="top">
        <div class="tleft">
          当前发布主题
          <el-tooltip
            content="这是客户访问你的在线商城时
看到的实时展示效果。"
            placement="top"
            effect="light"
          >
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </div>
        <div class="tright">
          <div class="cupo" @click="copyId($event)">复制链接</div>
          <div class="center">|</div>
          <el-popover placement="bottom" width="200" trigger="click">
            <template>
              <img class="qrcode" :src="qrcode" alt="" />
            </template>
            <div class="cupo" slot="reference">二维码预览</div>
          </el-popover>
        </div>
      </div>
      <div class="bot">
        <iframe :src="H5Path" frameborder="0"></iframe>
      </div>
    </div>
  </el-main>
</template>

<script>
import clipboard from '@/util/clipBoard.js';
export default {
  data() {
    return {
      appType: '微信小程序',
      appList: [
        { id: 2, name: '微信小程序', img: require('@/assets/image/site/weixin.png') },
        { id: 3, name: '支付宝小程序', img: require('@/assets/image/site/ali.png') },
        { id: 6, name: '抖音小程序', img: require('@/assets/image/site/douyin.png') },
        { id: 5, name: '百度小程序', img: require('@/assets/image/site/baidu.png') },
        { id: 4, name: '快应用', img: require('@/assets/image/site/quick.png') },
        { id: 7, name: 'QQ小程序', img: require('@/assets/image/site/qq.png') },
      ],
      stepList: [
        { title: '设置商品分类', isComplete: 0, path: '/goods/AddType' },
        { title: '添加商品', isComplete: 0, path: '/goods/addGoods' },
        { title: '店铺装修', isComplete: 0, path: '/addPages/customTemplate' },
        { title: '授权及发布小程序', isComplete: 0, path: '' },
      ],
      courseList: [
        { name: '商品管理教程', img: require('@/assets/image/site/goods.png') },
        { name: '店铺装修教程', img: require('@/assets/image/site/shop.png') },
        { name: '用户管理教程', img: require('@/assets/image/site/user.png') },
        { name: '基础设置教程', img: require('@/assets/image/site/basic.png') },
        { name: '短信设置教程', img: require('@/assets/image/site/msg.png') },
      ],
      H5Path: '',
      qrcode: '',
    };
  },
  computed: {
    // 用户的店铺信息
    siteInfo() {
      return this.$store.getters.shopInfo;
    },
  },
  created() {
    this.getInfo(2);
  },
  methods: {
    getInfo(id) {
      this.$axios
        .post(this.$api.shopDiy.shopGetInfo, {
          client_id: id,
        })
        .then(res => {
          if (res.code == 0) {
            this.stepList[0].isComplete = res.result.goods_cates_status == 1 ? 1 : 0;
            this.stepList[1].isComplete = res.result.goods_status == 1 ? 1 : 0;
            this.stepList[2].isComplete = res.result.diypage_status == 1 ? 1 : 0;
            this.stepList[3].isComplete = res.result.authorization_status == 1 ? 1 : 0;
            this.H5Path = res.result.url;
            this.qrcode = res.result.qrcode_url;
          }
        });
    },
    copyId(e) {
      clipboard(e, this.H5Path);
    },
    chooseApp(item) {
      this.appType = item.name;
      this.getInfo(item.id);
    },
    goDeal(item, index) {
      if (item.isComplete) return;
      if (index === 0) {
        this.$router.push('/goods/AddType');
      } else if (index === 1) {
        this.$router.push('/goods/addGoods');
      } else if (index === 2) {
        let routeData = this.$router.resolve('/addPages');
        window.open(routeData.href, '_blank');
      } else if (index === 3) {
        if (this.appType === '微信小程序') {
           this.$router.push('/shopManagement/wechatEcology/weixin');
        } else if (this.appType === '抖音小程序') {
          this.$router.push('/shopManagement/byterun');
        } else if (this.appType === '百度小程序') {
          this.$router.push('/shopManagement/Baidu');
        } else if (this.appType === '快应用') {
          this.$router.push('/shopManagement/quick');
        } else if (this.appType === 'QQ小程序') {
          this.$router.push('/shopManagement/QQ');
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  display: flex;
  .left {
    flex: 1;
    .title {
      color: #161b26;
      font-weight: bold;
      font-size: 20px;
    }
    .banner {
      width: 100%;
      height: 80px;
      background-image: url('../../assets/image/site/banner.png');
      background-size: 100% 100%;
      margin-top: 20px;
      padding-left: 28px;
      display: flex;
      align-items: center;
      .showBtn {
        width: 120px;
        line-height: 36px;
        background: #ffffff;
        border-radius: 4px;
        color: #1467ff;
        font-weight: bolder;
        font-size: 18px;
        text-align: center;
      }
      .desc {
        margin-left: 20px;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
      }
      .imgBox {
        margin-left: 12px;
        border-radius: 4px;
        background-color: transparent;
        border: 2px solid transparent;
      }
      .activeApp {
        background-color: #fff;
        border: 2px solid #fff;
      }
    }
    .newTitle {
      margin-top: 80px;
      margin-bottom: 20px;
    }
    .stepsBox {
      display: flex;
      align-items: center;
      & > div {
        display: flex;
        align-items: center;
      }
      .stepsItem {
        width: 140px;
        height: 150px;
        background: #fcfcfc;
        border: 1px solid #dadada;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        .num {
          width: 40px;
          height: 40px;

          border-radius: 50%;
          text-align: center;
          line-height: 40px;
          font-size: 18px;
          color: #fff;
        }
        .name {
          margin: 26px 0 12px 0;
        }
        .go {
          cursor: pointer;
          color: #1467ff;
        }
        .alreadyGo {
          cursor: pointer;
          color: #999;
        }
      }
      .isComplete {
        background-image: url('../../assets/image/site/selectd.png');
        background-size: 100% 100%;
      }
      .yuanBox {
        display: flex;
        align-items: center;
        .yuan {
          width: 6px;
          height: 6px;
          background: #e0e0e0;
          border-radius: 50%;
        }
        .yuanActive {
          background-color: #1467ff;
        }
      }
      .mr7 {
        margin-right: 7px;
      }
    }
    .courseList {
      display: flex;
      align-items: center;
      .courseItem {
        width: 200px;
        height: 70px;
        background: #f4f6fa;
        border: 1px solid #f0f0f0;
        border-radius: 2px;
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-right: 20px;
        }
        .img {
          margin: 0 12px 0 20px;
        }
        .name {
          font-size: 16px;
          color: #333;
        }
      }
    }
  }
  .right {
    flex: 0 0 405px;
    margin-left: 90px;
    .top {
      display: flex;
      justify-content: space-between;
      color: #333;
      i {
        color: #666;
      }
      .tright {
        display: flex;
        align-items: center;
        color: #666666;
        .cupo {
          cursor: pointer;
        }
        .center {
          margin: 0 9px;
          color: #d9d9d9;
        }
      }
    }
    .bot {
      margin: 0 auto;
      margin-top: 18px;
      width: 340px;
      height: 677px;
      background-image: url('../../assets/image/site/phoneBg.png');
      background-size: 100% 100%;
      position: relative;
      iframe {
        position: absolute;
        top: 38px;
        left: 8px;
        width: 322px;
        height: 630px;
        border-radius: 0 0 30px 30px;
        overflow: hidden;
      }
    }
  }
}
.qrcode {
  width: 180px;
  height: 180px;
}
</style>
